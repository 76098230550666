function App() {
  return (
      <div className="h-screen w-full bg-black text-white flex flex-col items-center justify-center space-y-4">
          <h1 className="text-4xl font-bold">Tomasz Kamiński</h1>
          <h2 className="text-2x1">
              <span className="text-gray-500">Software development && electronics</span>
          </h2>
          <nav className="flex flex-col items-center space-y-2">
              <div className="flex items-center space-x-2">
                  <div className="text-lg"/>
                  <a className="text-lg hover:underline" href="https://notes.tkaminski.pl">
                      Tech notes
                  </a>
              </div>
              <div className="flex items-center space-x-2">
                  <div className="text-lg"/>
                  <a className="text-lg hover:underline" href="https://github.com/kaminski-tomasz">
                      GitHub
                  </a>
              </div>
              <div className="flex items-center space-x-2">
                  <div className="text-lg"/>
                  <a className="text-lg hover:underline" href="https://www.linkedin.com/in/kaminski-tomasz/">
                      LinkedIn
                  </a>
              </div>
          </nav>
      </div>
  );
}

export default App;
